var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "upload" }, [
    _c(
      "div",
      { staticClass: "upload-list" },
      [
        _c("van-uploader", {
          attrs: { "after-read": _vm.afterRead, multiple: "", "max-count": 9 },
          model: {
            value: _vm.fileList,
            callback: function($$v) {
              _vm.fileList = $$v
            },
            expression: "fileList"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "number" }, [
      _vm._v("还可上传" + _vm._s(9 - _vm.fileList.length) + "张")
    ]),
    _c("div", { staticClass: "upload-btn", on: { click: _vm.onUpload } }, [
      _vm._v("上传")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }