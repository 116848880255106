<template>
  <div class="upload">
      <div class="upload-list">
        <van-uploader :after-read="afterRead" v-model="fileList" multiple :max-count="9" />
      </div>
      <div class="number">还可上传{{9-fileList.length}}张</div>
      <div class="upload-btn" @click="onUpload">上传</div>
  </div>
</template>

<script>

import {structuring} from "../../api";
import {Toast} from "vant";
export default {
  name: 'upload',
  data() {
    return {
      fileList:[],
    }
  },
   components: {

   },
  props: {

  },
  created() {

  },
   mounted() {

  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
     // this.fileList.push(file)
    },
    onUpload(){
      console.log(this.fileList)
      if(this.fileList.length == '0'){
        Toast('请选择病例');
      }else {
        const toast = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
        });
        let files = [];
        for(let i = 0; i < this.fileList.length; i++){
          files.push(this.fileList[i].file)
        }
        let query={
          sessionId:this.$store.getters.session,
          files:files
        }
        console.log(query)
        structuring(query).then((res)=>{
          toast.clear()
          this.$router.go(-1);//返回上一层
        })
      }
    }

  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.upload{
  width: 100%;
  height: 100%;
  background: #F3F4FC;
  position: fixed;
  left: 0;
  top: 0;
  .upload-list{
    margin: .3rem;
    background: #fff;
    border-radius: .16rem;
    padding: .4rem 0 .4rem .4rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .upload-btn{
    position: fixed;
    bottom: 0;
    right: 0;
    background: #0068FF;
    color: #fff;
    width: 100%;
    height: 1.12rem;
    text-align: center;
    font-size: .28rem;
    font-weight: 500;
    line-height: 1.12rem;
  }
  .add{
    width: 1.42rem;
    height: 1.7rem;
    background: #F3F4FC;
    border-radius: .08rem;
    font-size: .24rem;
    color: #131313;
    text-align: center;
    padding-top: .5rem;
    position: relative;
    margin-bottom: .3rem;
    margin-left: .2rem;
    input{
      position: absolute;
      right: 0px;
      top: 0px;
      width: 1.06rem;
      height: 1.28rem;
      opacity: 0;
    }
  }
  .list-img{
    width: 1.42rem;
    height: 1.7rem;
    position: relative;
    margin-left: .2rem;
    margin-bottom: .3rem;
    .close{
      position: absolute;
      width: .24rem;
      height: .24rem;
      background: #FF0000;
      color: #fff;
      border-radius: 50%;
      right: 0;
      top: -3px;
      line-height: .24rem;
    }
    .img-style{
      width: 1.42rem;
      height: 1.7rem;
      border-radius: .08rem;
    }
  }
  .number{
    font-size: .24rem;
    color: #C4C4C4;
    margin-left: .3rem;
  }

}
</style>
<style>
.van-uploader__preview{
  margin: 0 24px 15px 0;
}
</style>
